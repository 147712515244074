<template>
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_783_17837)">
            <path d="M10.5517 10.2981V11.5348C10.5517 13.2414 11.9421 14.6265 13.6552 14.6265H16.1379V15.2449C16.1379 16.2651 15.3 17.0999 14.2759 17.0999H1.86207C0.837931 17.0999 0 16.2651 0 15.2449V6.58796C0 5.5615 0.837931 4.73291 1.86207 4.73291H14.2759C15.3 4.73291 16.1379 5.5615 16.1379 6.58796V7.20631H13.6552C11.9421 7.20631 10.5517 8.59141 10.5517 10.2981ZM18 10.2981V11.5348C18 12.0789 17.7579 12.5736 17.3793 12.9137C17.0503 13.2105 16.6097 13.3898 16.1379 13.3898H13.6552C12.631 13.3898 11.7931 12.555 11.7931 11.5348V10.2981C11.7931 9.27159 12.631 8.44301 13.6552 8.44301H16.1379C16.6097 8.44301 17.0503 8.62233 17.3793 8.91914C17.7579 9.25304 18 9.74772 18 10.2981ZM14.8966 10.9164C14.8966 10.5763 14.6172 10.2981 14.2759 10.2981C13.9345 10.2981 13.6552 10.5763 13.6552 10.9164C13.6552 11.2565 13.9345 11.5348 14.2759 11.5348C14.6172 11.5348 14.8966 11.2565 14.8966 10.9164ZM12.7924 2.24714C12.6372 1.72154 12.271 1.29488 11.7745 1.06609C11.2779 0.843486 10.7069 0.843486 10.2103 1.07228L5.00276 3.49621H13.1524L12.7924 2.24714Z" fill="currentColor" />
        </g>
        <defs>
            <clipPath id="clip0_783_17837">
                <rect width="18" height="18" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>
